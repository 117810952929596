import { PostSummary } from '~/types'
import { PostCard } from './post-card'
import { LinksFunction } from '@remix-run/node'
import post from './post.css?url'

export const postCollectionLinks: LinksFunction = () => [
  { rel: 'stylesheet', href: post },
]

export const PostCollection = ({ posts }: { posts: PostSummary[] }) => (
  <div className="post-collection">
    {posts.length === 0 && <p>No posts found.</p>}
    {posts.map((post) => (
      <PostCard key={post.slug} {...post} />
    ))}
  </div>
)
