import { Link } from '@remix-run/react'
import clsx from 'clsx'
import { BlogPostBanner } from '~/components/blog/blog-post-banner'
import { P } from '~/components-v2'
// import { getAuthorNames } from '~/utils/get-author'
import { PublishDate } from '~/components/blog/publish-date'
import { PostSummary } from '~/types'

export const PostCard = ({
  banner,
  cover,
  slug,
  authors,
  published,
  subtitle,
  title,
  description,
  loading,
}: PostSummary & {
  loading?: 'lazy' | 'eager'
  cover?: boolean
}) => {
  const coverContent = cover && (
    <>
      <h2>{subtitle}</h2>
      <p>{description}</p>
    </>
  )

  return (
    <article className="post-card-container">
      <Link
        to={`/blog/${slug}`}
        className={clsx('post-card', cover && 'post-card-cover')}
      >
        {banner && <BlogPostBanner loading={loading} {...banner} />}
        <div className="content">
          <h1>{title}</h1>
          {coverContent}
          <P color="text4" fontSize="sm">
            <PublishDate date={published} />
            {!!authors.length && (
              <span className="authors">
                {authors.map(({ name, slug }) => (
                  <span key={slug} className="author">
                    {name}
                  </span>
                ))}
              </span>
            )}
          </P>
        </div>
      </Link>
    </article>
  )
}
